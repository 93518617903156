@import "./../index.scss";
.court_ann_box {
    width: 100%;
    padding: 30px 0px 10px 0px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .court_ann_list {
      width: 580px;
      height: 160px;
      display: flex;
      padding: 10px;
      margin-top: 20px;
      background: #f9f9f9;
      border: solid 1px #e2e2e2;
      align-items: center;
      img {
        width: 160px;
        height: 130px;
        margin-right: 10px;
      }
      .court_ann_content {
        flex: 1;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h6 {
          font-size: 16px;
          height: 36px;
          color: $color333;
          font-weight: bold;
        }
        .court_ann_text {
          flex: 1;
          font-size: 14px;
          margin-top: 10px;
          color: $color666;
        }
      }
    }
  }
