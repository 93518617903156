@import "./../index.scss";
.list_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 290px;
    height: 340px;
    border: solid 1px #E6E6E6;
    background: $fff;
    position: relative;
    margin-bottom: 20px;
    margin-right: 26px;
    border-radius: 4px;
    .li_img{
      width: 100%;
      height: 180px;
      overflow: hidden;
      position: relative;
      .li_status{
        width: 100%;
        height: 30px;
        display: flex;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0,0,0,.7);
        justify-content: space-between;
        .li_status_left{
          width: 94px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          background: rgba(56,127,252,.7);
          border-radius: 0 50px 50px 0;
        }
        .status_af{
          background: rgba(245,104,10,.7);
        }
        .status_on{
          background: rgba(87, 85, 84, 0.7);
        }
        .li_status_right{
          flex: 1;
          line-height: 30px;
          color: $fff;
          font-size: 14px;
          text-align: right;
          padding-right: 5px;
        }
      }
    }
    img {
      width: 100%;
      height: 180px;
      transition: all .3s;
    }
    h3 {
      line-height: 40px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      padding: 0 10px;
      color: $color4b;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      padding: 0 10px;
      line-height: 32px;
      em {
        color: #E70017;
      }
      b {
        font-size: 18px;
        color: #E70017;
      }
      b.color {
        color: #E70017;
      }
      span {
        color: $color84;
        font-size: 12px;
      }
      b.end {
        color: #161616;
        font-size: 15px;
      }
    }
    button {
      background: $blue46B;
      color: $fff;
      font-size: 14px;
      border: none;
      width: 100%;
      height: 30px;
      outline: none;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 16px;
        font-weight: 600;
      }
      .one8 {
        color: rgba(255, 255, 255, 0.8);
      }
      .one6 {
        color: rgba(255, 255, 255, 0.6);
      }
      .one4 {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    button.end {
      background: $buttonBg;
      color: #373737;
    }
    button.green {
      background: $green;
    }
  }
  li:hover {
    box-shadow: 0px 0px 3px 1px #bfbfbf;
    background: #F5F8FC;
    img{
      transform: scale(1.2);
    }
  }
  a:nth-child(4n) li{
    margin-right: 0;
  }
}

.narrow{
  li{
    margin-right: 9px;
  }
  a:nth-child(3n) li{
    margin-right: 0!important;
  }
  a:nth-child(4n) li{
    margin-right: 9px;
  }
}
