@import "./../index.scss";
.hot_city{
  width: 100%;
  display: flex;
  padding: 16px 0;
  font-size: 14px;
  font-weight: bold;
  span{
    margin-left: 16px;
    cursor: pointer;
  }
  span:hover{
    color: $blueHover;
  }
}
.city-list-box {
    width: 100%;
    border: solid 1px $borderColor;
    height: 600px;
    overflow-y: auto;
    ul {
      width: 100%;
      li {
        width: 100%;
        border-bottom: solid 1px $borderColor;
        position: relative;
        background: $fff;
        align-items: center;
        padding-left: 60px;
        > span {
          background: #eee;
          font-size: 18px;
          width: 60px;
          height: 100%;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .city-list-1-box {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        .city-list-1 {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: solid 1px $borderColor;
          > span {
            font-size: 18px;
            width: 120px;
            padding-left: 30px;
            font-weight: bold;
            color: $color4b;
          }
          > em {
            width: 0;
            height: 0;
            display: none;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -8px;
            border-left: solid 8px $blueHover;
            border-right: solid 8px transparent;
            border-bottom: solid 8px transparent;
            border-top: solid 8px transparent;
          }
          .city-list-2 {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            padding-top: 5px;
            span {
              margin-right: 20px;
              font-size: 14px;
              padding-bottom: 5px;
              cursor: pointer;
            }
            span:hover{
              color: $blueHover;
            }
          }
        }
  
        .city-list-1:hover {
          background: #eee;
        }
        .city-list-1:hover em {
          display: block;
        }
        .city-list-1:last-child {
          border: none;
        }
      }
      li:last-child {
        border: none;
      }
      li:hover > span {
        background: $blueHover;
        color: $fff;
      }
    }
  }