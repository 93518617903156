@import "./../index.scss";
.wx_login_box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  .login_modal {
    width: 460px;
    height: 500px;
    background: $fff;
    position: relative;
    padding-top: 20px;
    h6 {
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
    .anticon {
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .login_nav {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      span {
        flex: 1;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
      }
      b {
        margin-bottom: 6px;
      }
      span.active {
        color: $blue46B;
      }
    }
    .impowerBox {
      .title {
        display: none;
      }
    }
    .login_content {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .wx_login_bg {
    width: 400px;
    height: 360px;
    position: relative;
    overflow: hidden;
  }
  #login_container,
  #login_container_modal {
    width: 400px;
    height: 370px;
    background: $fff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.phone_login {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  .ant-form-horizontal {
    height: 248px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-left: -70% \9;
    .ant-form-item {
      width: 100%;
    }
    .input_width {
      width: 250px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-form-horizontal {
      margin-left: -70%;
      .input_width {
        width: 454px;
      }
    }
  }
  .ant-col-18 {
    margin: auto;
    input {
      border: none;
      border-bottom: solid 1px $borderColor;
      outline: none;
    }
    .code_btn {
      background: $fff;
      color: $color4b;
      border: solid 1px $blue46B;
      font-size: 12px;
    }
    .btn_sub {
      width: 100%;
      height: 40px;
    }
  }
  .ant-input-group-compact {
    display: flex;
    justify-content: space-between;
  }
}

