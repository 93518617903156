code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input,
figure {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #656565;
}

// html{scroll-behavior: smooth;}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  color: #4B4B4B;
  // background: #f1f1f1;
}

a,
a:hover {
  text-decoration: none;
  outline: none;
}

li {
  list-style: none;
}

img {
  border: none;
}

input,
select,
textarea {
  border: none;
  outline: none;
  background: none;
}

em,
i {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

#root{
  width: 100%;
  height: 100%;
}

$fff:#fff;
$color999:#999;
$color333:#333;
$color666:#666;
$color45:#455569;
$blueActive:#0E3888;
$blue46B:#1479D7;
$footerBg:#3580C5;
$blueHover:#0C82FF;
$blue4f:#4FBCE0;
$borderColor:#E6E6E6;
$bgf5:#F5F8FC;
$color4b:#202020;
$color84:#848484;
$color9b:#9B9B9B;
$divBg:#D6D6D6;
$buttonBg:#A0A0A0;
$bge4:#E4E4E4;
$green:#1CB472;
$green02:#0C82FF;
$green0f:#0FB184;
$btnBg:#9BCAFF;
$bgf7:#F7F7F7;
$borderccc:#ccc;
$listBg:#CAE0F3;
$detailsBlue:#4889F3;

.session{
  width: 1240px;
  margin: auto;
}
.more_box{
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: #1479D7;
  text-align: center;
  cursor: pointer;
}
.title_h6{
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  span{
    color: #1479D7;
  }
}
img{
  max-width: 100%;
}
.nowrap{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-btn-primary[disabled]{
  background: #eee;
}
.ant-form-item .ant-form-item{
  margin-bottom: 0;
}
a:hover{
  color: #0C82FF !important;
}
.ant-message{
  z-index: 999999999;
}
button{
  a:hover{
    color: $fff !important;
  }
}
.user_code{
  a:hover{
    color: $fff !important;
  }
}
.btn:hover{
  color: $fff !important;
}