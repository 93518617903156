@import "./../index.scss";
.code_box {
  width: 40px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2px;
  top: 30%;
  z-index: 9999;
  .code_list {
    width: 40px;
    height: 40px;
    background: #F5F8FC url(./../img/center.png) no-repeat center center;
    background-size: 20px 20px;
    position: relative;
    border: solid 1px #C9DFF4;
    margin-bottom: 2px;
    .code_after{
      width: 130px;
      height: 130px;
      border: solid 2px #1479D7;
      background: #F5F8FC url(./../img/wx_code.jpg) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -44px;
      left: -136px;
      display: none;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: -14px;
        top: 54px;
        border-top: solid 6px transparent;
        border-right: solid 6px transparent;
        border-left: solid 6px #1479D7;
        border-bottom: solid 6px transparent;
      }
    }
    .share_box{
      width: 130px;
      height: 130px;
      border: solid 2px #1479D7;
      position: absolute;
      top: -44px;
      left: -136px;
      display: none;
      align-items: center;
      justify-content: center;
      background: #fff;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: -14px;
        top: 54px;
        border-top: solid 6px transparent;
        border-right: solid 6px transparent;
        border-left: solid 6px #1479D7;
        border-bottom: solid 6px transparent;
      }
    }
    &:hover {
      background: $blue46B url(./../img/center1.png) no-repeat center center;
      background-size: 20px 20px;
    }
    .user_content {
      position: absolute;
      left: -136px;
      top: 0;
      background: #1479D7;
      color: #fff;
      font-size: 14px;
      text-align: center;
      height: 40px;
      width: 130px;
      line-height: 40px;
      display: none;
      span{
        cursor: pointer;
      }
      a {
        color: #fff;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: -12px;
        top: 15px;
        border-top: solid 6px transparent;
        border-right: solid 6px transparent;
        border-left: solid 6px #1479D7;
        border-bottom: solid 6px transparent;
      }
    }
    .user_code{
      width: 100%;
      height: 100%;
      z-index: 999;
      left: 0;
      top: 0;
      font-size: 12px;
      display: none;
      align-items: center;
      justify-content: center;
      line-height: 14px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      background: $blue46B;
      a,span{
        color: $fff;
        cursor: pointer;
      }
    }
  }
  .code_list:nth-child(3) {
    background: #fff url(./../img/code.png) no-repeat center center;
    background-size: 20px 20px;
    &:hover {
      background: $blue46B url(./../img/code1.png) no-repeat center center;
      background-size: 20px 20px;
    }
  }
  // .code_list:nth-child(2) {
  //   background: #fff url(./../img/yan.png) no-repeat center center;
  //   background-size: 20px 13px;
  //   &:hover {
  //     background: $blue46B url(./../img/yan1.png) no-repeat center center;
  //     background-size: 20px 13px;
  //   }
  // }
  .code_list:nth-child(2) {
    background: #fff url(./../img/server.png) no-repeat center center;
    background-size: 20px 20px;
    &:hover {
      background: $blue46B url(./../img/server1.png) no-repeat center center;
      background-size: 20px 20px;
    }
  }
  .code_list:nth-child(4) {
    background: #fff url(./../img/fankui.png) no-repeat center center;
    background-size: 20px 20px;
    &:hover {
      background: $blue46B url(./../img/fankui1.png) no-repeat center center;
      background-size: 20px 20px;
    }
  }
  .code_list:nth-child(5) {
    background: #fff url(./../img/share.png) no-repeat center center;
    background-size: 20px 20px;
    &:hover {
      background: $blue46B url(./../img/share_hover.png) no-repeat center center;
      background-size: 20px 20px;
    }
  }
  .code_list:nth-child(6) {
    background: #fff url(./../img/top.png) no-repeat center center;
    background-size: 17px 10px;
    &:hover {
      background: $blue46B url(./../img/top1.png) no-repeat center center;
      background-size: 17px 10px;
    }
  }
  .code_list:hover .code_after {
    display: block;
  }
  .code_list:hover .share_box {
    display: flex;
  }
  .code_list:hover .user_content {
    display: block;
  }
  .code_list:hover .user_code {
    display: flex;
  }
}
