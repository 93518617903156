@import "./../index.scss";
.about_cooperation_box {
  width: 100%;
  .about_banner{
      width: 100%;
      height: 400px;
      background: url(./../img/about_banner.png) no-repeat;
      background-size: 100% 100%;
      line-height: 400px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      font-size: 40px;
  }
  .cooperation_title {
    height: 300px;
    padding: 20px 50px;
    background: #fff;
    h1 {
      color: #333;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h2 {
      text-align: center;
      color: #b3b3b3;
      font-size: 16px;
    }
    >p {
      color: #666;
      font-size: 16px;
      margin-top: 60px;
    }
    >div {
        margin-top: 10px;
        p{
            color: #666;
          font-size: 16px;
          margin-bottom: 10px;
        }
    }
  }
  .cooperation_list {
    width: 100%;
    display: flex;
    height: 500px;
    padding: 0 50px;
    align-items: center;
    background: #eff3f7;
    justify-content: center;
    .cooperation_list_img {
      width: 550px;
      height: 280px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 280px;
        
      }
    }
    .cooperation_list_content {
      flex: 1;
      padding: 0 30px 0 0;
      h3 {
        color: #333;
        font-size: 18px;
        margin-bottom: 80px;
      }
      p {
        color: #707070;
        font-size: 14px;
        display: flex;
        em {
          width: 20px;
          height: 20px;
          background: url(./../img/us-phone.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 10px;
        }
        em.wx {
          background: url(./../img/us-wx.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .cooperation_list:nth-child(2n-1) {
    background: #fff;
    .cooperation_list_content {
      padding: 0 0 0 30px;
    }
  }
}
